<template>

  <div class="wrapper">

    <div class="body" style="padding: ">

      <div class="row m-0 p-0" style="background-color: var(--primary)">

        <div class="casino-headers col-12" style="">
          <span>Live Casino</span>
        </div>

        <div class="d-none " v-for="(o, index) in allgamesdata" :key="getKey(index)">

          <a :href="getReference('d-',o.game_id)" >

            <div v-show="o.limits.length > 0" class="limits" v-text="o.limits"></div>

            <img loading="lazy" :src="getImg(o.image)"  alt="Avatar" style="max-width: 100%;height: 140px;border-radius: 5px;"/>

            <div v-show="o.last20Results.length > 0" class="row m-0 p-0" style="">

              <div class="col-6 m-0 p-0">
                <div v-show="o.open_status.length > 0" class="open-status" v-text="o.open_status"></div>
              </div>

              <div class="col-6 m-0 p-0">

                <div class="row m-0 p-0">

                  <div class="col-4 m-0 p-0 last-results text-center" v-for="(v,k) in o.last20Results" :key="getKey(k)" v-bind:style="{backgroundColor: v.color}">
                    <span v-text="v.result" style="font-size:12px"></span>
                  </div>

                </div>

              </div>

            </div>

            <div v-show="o.last20Results.length === 0 " class="open-status1 d-none" v-text="o.open_status"></div>

          </a>

          <!-- Modal -->
            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content" style="background-color: #353b40">
                  <div class="modal-body">
                                
                  </div>
                </div>
              </div>
            </div>

          <!-- modal here -->
          <div :id="getID('d-',o.game_id)" class="modal-window" style="color: white">

            <div style="background-color: #353b40">

              <a href="#" title="Close" class="modal-close">X</a>

              <h1>{{ o.game_name }}</h1>

              <div class="row">

                <div class="col-8">
                  <img loading="lazy" :src="o.image" alt="gameimg" height="100"/>
                </div>

                <div class="col-4">

                  <div class="row">

                    <router-link class="col-12" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                      <button class="btn btn-success"><b>Play Now</b></button>
                    </router-link>

                  </div>

                </div>

              </div>

            </div>
          </div>
          <!-- modal here -->

          <div class="casino-headers row d-none m-0 p-0" style="font-size: 12px;background-color: black;padding: 0px 20px !important;margin: 0">
            <span class="text-left col-10 p-0 m-0" v-text="o.game_name"></span>
            <span class="text-right col-2 p-0 m-0" v-html="o.players"></span>
          </div>

        </div>

        <div class="card casino-card col-lg-2 col-md-2 col-sm-2 col-4 m-0 p-0"  data-toggle="modal" :data-target="'#'+getID('m-',o.game_id)" v-for="(o, index) in allgamesdata" :key="getKey(index)">

          <a class="live-casino-img">
            <div v-show="o.limits.length > 0" class="limits" v-text="o.limits"></div>
            <img loading="lazy" :src="getImg(o.image)" alt="Avatar" style="max-width: 100%;width: 100%;border-radius: 5px;border: "/>

            <div v-show="o.last20Results.length > 0" class="row m-0 p-0" style="">

              <div class="col-4 m-0 p-0">
                <div v-show="o.open_status.length > 0" class="open-status" v-text="o.open_status"></div>
              </div>

              <div class="col-8 m-0 p-0">
                <div class="row m-0 p-0">
                  <div class="col-4 m-0 p-0 last-results text-center" v-for="(v,k) in o.last20Results" :key="getKey(k)" v-bind:style="{backgroundColor: v.color}">
                      <span v-text="v.result"></span>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="o.last20Results.length === 0" class="open-status1" v-text="o.open_status"></div>
          </a>
           <div :id="getID('m-',o.game_id)" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content" style="background-color: #353b40">
                  <div class="modal-body">
                      <div style="background-color: #353b40">
                        <div class="d-flex">
                          <a title="Close" class="modal-close text-light hover-pointer">X</a>

                          <h6 class="text-light">{{ o.game_name }}</h6>
                        </div>
                        <img loading="lazy" :src="o.image" alt="gameimg" class="ww-100 mb-2"/>

                        <router-link class="ww-100" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                          <button class="btn btn-success ww-100 mb-2"><b>Play Now</b></button>
                        </router-link>

                      </div>
                  </div>
                </div>
              </div>
            </div>
          <!-- modal here -->
          <div :id="getID('m-2',o.game_id)" class="modal-window" style="color: white">

            <div style="background-color: #353b40">

              <div class="d-flex">
                <a href="#" title="Close" class="modal-close">X</a>

                <h1>{{ o.game_name }}</h1>
              </div>

              <img loading="lazy" :src="o.image" alt="gameimg" class="ww-100 mb-2"/>

              <router-link class="ww-100" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                <button class="btn btn-success ww-100 mb-2"><b>Play Now</b></button>
              </router-link>

            </div>

          </div>
          <!-- modal here -->

          <div class="casino-headers row d-none m-0 p-0" style="font-size:12px;background-color: transparent;padding: 0px 20px !important;margin: 7px 0px;">
            <span class="text-left col-10 m-0 p-0" v-text="o.game_name"></span>
            <span class="text-right col-2 m-0 p-0" v-html="o.players"></span>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 5px;
}

/* .modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
} */

.limits {
  background-color: var(--secondary);
  color: #fff;
  font-size: 0.7em;
  font-weight: 600;
  position: absolute;
  padding: 3px 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.open-status {
  background-color: #d32027;
  color: #fff;
  font-size: 0.7em;
  font-weight: 600;
  padding: 0px 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  position: absolute;
}

.open-status1 {
  background-color: #d32027;
  color: #fff;
  font-size: 0.7em;
  font-weight: 600;
  position: absolute;
  padding: 3px 10px;
  -webkit-border-top-right-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  margin-left: 0px;
}

.live-casino-img {


}

.last-results {

  border: 1px solid #565454;
  border-radius: 2px;
  font-weight: 600;
  color: white;
}

</style>
<script>
import axios from "@/services/api";
export default {
  name: "Live",
  components: {
    
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      loading: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      tableIDs:[]
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino-live");

    this.getCasinoGames();

    this.reloadProfile();

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {

    openModal: function(id){

      this.jQuery('#live-casino-'+id).modal('show');

    },
    getReference: function(prefix,game_id){

      return '#'+prefix+'live-casino-'+game_id;

    },
    getID: function(prefix,game_id){

      return prefix+'live-casino-'+game_id;

    },
    getLimitID: function (game_id,platform){

      return platform+"-limit-id-"+game_id;

    },
    getStatsID: function (game_id,platform){

      return platform+"-stats-id-"+game_id;

    },
    getOpenID: function (game_id,platform){

      return platform+"-table-open-id-"+game_id;

    },
    loadPage: function(launch_url){

      window.open(launch_url, '_blank');
    },
    getImg: function (img){

      return img.replaceAll("/rec/325/","/square/200/")

    },
    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if(slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function (){

        vm.showSlides();

      },2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      return launch_url.replaceAll("profile_token", tokn);

    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function () {

      var vm = this;

      var platform = this.isMobile() ? 'MOBILE' : 'DESKTOP';
      var path = process.env.VUE_APP_CASINO_LIVE_GAMES_URL;
      path = path.replace("{provider_id}", 0);
      path = path.replace("{platform}", platform);
      path = path.replace(":provider_id", 0);
      path = path.replace(":platform", platform);

      const passtoken = {};

      axios
          .post(path, passtoken)
          .then((resp) => {

            vm.tableIDs = [];
            vm.allgamesdata = [];

            var allgames = resp.data.message;

            vm.jQuery.each(allgames,function (k,v){

              vm.jQuery.each(v.games,function(kk,vv){

                vv.game_type = vm.getGameType(vv.game_id);
                vv.last20Results = [];
                vv.limits = '';
                vv.open_status = '';

                if(!isNaN(vv.game_id)) {

                  vm.tableIDs.push(vv.game_id);

                }

                vv.limits = "";

                vm.allgamesdata.push(vv);

              })

            });

            vm.getCasinoEvents();
            console.log("allgames ==>"+JSON.stringify(vm.allgamesdata));

          });
    },

    getGameType: function(gameID){

      var vm = this;

      var blackjack = [301,302,303,304,305,511,512,513,514,515,516,517,518,519,520,521,522,523,524,525,526,527,528,529,530,535,536,537,538,539,540,541,542,543,544,901,902];
      var baccarat = [401,402,403,404,405,411,412,413,414,415,421,422,423,424,425,426,427,429,430,431,432];
      var sicbo = [701];
      var mega_wheel = [801];
      var roulette = [201,203,204,206,225,227,229,545,230];
      //var roulette = [201,203,206,225,227,545,230,204];
      var sweet_bonanza = [1101];
      var dragon_tiger = [1001];
      var andarbahar = [1024];

      var games = [];
      games.push({name: "blackjack", list: blackjack})
      games.push({name: "baccarat", list: baccarat})
      games.push({name: "sicbo", list: sicbo})
      games.push({name: "mega_wheel", list: mega_wheel})
      games.push({name: "roulette", list: roulette})
      games.push({name: "sweet_bonanza", list: sweet_bonanza})
      games.push({name: "dragon_tiger", list: dragon_tiger})
      games.push({name: "andarbahar", list: andarbahar})

      var gameName = "";

      this.jQuery.each(games,function(k,v){

            vm.jQuery.each(v.list,function(kk,vv){

              if(parseInt(vv) === parseInt(gameID)) {

                gameName = v.name;
              }
            });
      });

      return gameName;
    },

    getCasinoEvents: function (){

      var path = process.env.VUE_APP_PROVIDER_5_WSURL_PROD;
      var casinoID = process.env.VUE_APP_PROVIDER_5_CASINO_ID;

      var vm = this;

      // connect
      vm.DGA.connect(path);

      // on connect
      vm.DGA.onConnect = function () {

        var mx = vm.tableIDs.length
        var x = 0;
        vm.jQuery.each(vm.tableIDs,function (k,tableID) {

          // subscribe
          vm.DGA.subscribe(casinoID,tableID,'KES');

        })

        vm.DGA.onMessage = function (data) {

          if(data) {

            x++;
            //console.log(data);
            var tableId = data.tableId;
            var minBet = data.tableLimits ? vm.formatStake(data.tableLimits.minBet) : '-';
            var maxBet = data.tableLimits ? vm.formatStake(data.tableLimits.maxBet) : '-';
            var maxPlayers = data.tableLimits ? data.tableLimits.maxPlayers : 0;
            var players = data.totalSeatedPlayers;
            var tableOpen = data.tableOpen;
            var gameType = vm.getGameType(tableId);
            var openText = tableOpen ? 'Game Open' : 'Game Closed';
            var playersTxt = players + " <img height='20px' src='/assets/images/users.svg'></img>";
            var isFull = players >= maxPlayers;

            vm.jQuery.each(vm.allgamesdata,function (k,v){

              if(parseInt(v.game_id) === parseInt(tableId)) {

                v.limits = minBet + " - " + maxBet + " KES";
                v.open_status = openText;
                v.players = playersTxt;
                v.is_full = isFull;

                if(data.last20Results) {

                  var last20Results = data.last20Results

                  console.log(tableId+" gameType "+gameType+" ==> "+JSON.stringify(last20Results));

                  if(last20Results) {

                    var dt = last20Results.slice(0, 6);
                    var fV = [];

                    vm.jQuery.each(dt,function(ky,vl){

                      if(vl.color) {

                        fV.push(vl);
                      }

                    });

                    v.last20Results = fV;//last20Results.slice(0, 6);

                  }
                }

                vm.allgamesdata[k] = v

                if(x === mx) {

                  vm.DGA.disconnect();

                }

              }
            })

          }

        }

      }
    }
  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>