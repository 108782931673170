<template>
  <div class="wrapper">
    <div class="body pb-5" style="">
      <div v-if="allgamesdata.length > 0">
        <div class="row m-0 p-0" v-for="(i, x) in allgamesdata" :key="getKey(x)" :id="i.category_name"
             style="background-color: var(--primary)">

          <div class="casino-headers col-12" style="">
            <span v-text="i.category_name"></span>
          </div>

          <div class="card  casino-card  col-lg-2 col-md-2 col-sm-2 col-4 m-0 p-0 m-0 p-0" v-for="(o, index) in i.games"
               :key="getKey(index)">

            <a v-bind:href="getReference(o.game_id)">
              <img :src="getImg(o.image)" alt="Avatar"
                   style="
                  max-width: 100%;
                  border-radius: 5px;
                "
              />
            </a>

            <!-- modal here -->
            <div :id="getID(o.game_id)" class="modal-window" style="color: white">

              <div style="background-color: #353b40">

                <a href="#" title="Close" class="modal-close">X</a>

                <h1>{{ o.game_name }}</h1>


                <img :src="o.image" alt="gameimg" class="ww-100 mb-2"/>


                <div class="text-center" v-show="i.category_id !== 24 && i.category_id !== 15 ">

                  <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {isDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                    Play Now
                  </router-link>

                </div>

              </div>

            </div>
            <!-- modal here -->
          </div>

          <div class="card col-6 d-none" v-for="(o, index) in i.games" :key="getKey(index)">

            <a :href="'#' + o.game_id">
              <img :src="getImg(o.image)" alt="Avatar"
                   style="
                  max-width: 100%;
                  border-radius: 10px;
                  border: 1px solid #ddd;
                "
              />
            </a>

            <!-- modal here -->
            <div :id="o.game_id" class="modal-window" style="color: white">

              <div style="background-color: #353b40">

                <a href="#" title="Close" class="modal-close">X</a>

                <h1>{{ o.game_name }}</h1>

                <div>
                  <img :src="o.image" alt="gameimg" height="100"/>
                </div>

                <div class="text-center" v-show="i.category_id !== 24 && i.category_id !== 15 ">

                  <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {isDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                    Play Now
                  </router-link>

                </div>

                <div class="text-center" v-show="i.category_id === 24 || i.category_id === 15 ">

                  <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {isDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                    Play Now
                  </router-link>

                </div>

              </div>
            </div>
            <!-- modal here -->
          </div>


        </div>
      </div>
      <div v-else>
        <!-- Display an SVG image or any message when there are no games -->
        <div class="no-games-message">
          <!-- You can replace the content inside this div with your SVG image or message -->
          No games available at the moment.
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 5px;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.no-games-message {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  color: #283544;
  background-color: #ffffff;
  border: 1px solid #283544;
  border-radius: 5px;
  margin: 20px;
}
</style>
<script>
import axios from "@/services/api";

export default {
  name: "Virtuals",
  components: {},
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      loading: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "virtuals");

    this.getCasinoGames();

    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {

    getReference: function (game_id) {

      return '#virtuals-' + game_id;

    },
    getID: function (game_id) {

      return 'virtuals-' + game_id;

    },
    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },
    getImg: function (img) {

      return img;//.replaceAll("/rec/325/","/square/200/")

    },
    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      console.log('GOT launch_url ' + launch_url);
      console.log('GOT tokn ' + tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      console.log('GOT tokenized launch_url ' + launch_url);

      return launch_url;

    },


    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function () {

      var platform = this.isMobile() ? 'MOBILE' : 'MOBILE';
      var path = process.env.VUE_APP_CASINO_GAMES_URL;
      path = path.replace("{category_id}", 14);
      path = path.replace("{provider_id}", 0);
      path = path.replace("{platform}", platform);

      const passtoken = {};

      axios
          .post(path, passtoken)
          .then((resp) => {

            var allgames = resp.data.message;
            this.allgamesdata = allgames;
            console.log(allgames);
          });
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>